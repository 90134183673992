@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

* {
    padding: 0;
    margin: 0;
}

i {
    font-family: 'Font Awesome 6 Free' !important;
}

a {
    text-decoration: none;
}

@font-face {
    font-family: 'MyFlutterAppAll';
    src: url('fonts/MyFlutterAppAll.ttf') format('truetype');
}

@font-face {
    font-family: 'ExternalAssetIcons';
    src: url('fonts/ExternalAssetIcons.ttf') format('truetype');

}

@font-face {
    font-family: 'MaterialSymbolsOutlined';
    src: url('fonts/MaterialSymbolsOutlined[FILL\,GRAD\,opsz\,wght].ttf') format('truetype');

}


@font-face {
    font-family: "BebasNeueThin";
    src: local("BebasNeueThin"),
        url("fonts/Bebas-Neue-Light/BebasNeue\ Thin.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "BebasNeueRegular";
    src: local("BebasNeueRegular"),
        url("fonts/Bebas-Neue-Light/BebasNeue\ Regular.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "BebasNeueBold";
    src: local("BebasNeueBold"),
        url("fonts/Bebas-Neue-Light/BebasNeue\ Bold.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.material-symbols-outlined {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

/* .icon-0::before {
    font-family: 'MyFlutterAppAll';
    font-weight: bold;
    content: '\E848';
} */

.icon-0::before {
    font-family: 'MyFlutterAppAll';
    content: '\E800';
}

.icon-1::before {
    font-family: 'MyFlutterAppAll';
    content: '\E801';
}

.icon-2::before {
    font-family: 'MyFlutterAppAll';
    content: '\E802';
}

.icon-3::before {
    font-family: 'MyFlutterAppAll';
    content: '\E803';
}

.icon-4::before {
    font-family: 'MyFlutterAppAll';
    content: '\E804';
}

.icon-5::before {
    font-family: 'MyFlutterAppAll';
    content: '\E805';
}

.icon-6::before {
    font-family: 'MyFlutterAppAll';
    content: '\E806';
}

.icon-7::before {
    font-family: 'MyFlutterAppAll';
    content: '\E807';
}

.icon-8::before {
    font-family: 'MyFlutterAppAll';
    content: '\E808';
}

.icon-9::before {
    font-family: 'MyFlutterAppAll';
    content: '\E809';
}

.icon-10::before {
    font-family: 'MyFlutterAppAll';
    content: '\E80a';
}

.icon-11::before {
    font-family: 'MyFlutterAppAll';
    content: '\E80b';
}

.icon-12::before {
    font-family: 'MyFlutterAppAll';
    content: '\E80c';
}

.icon-13::before {
    font-family: 'MyFlutterAppAll';
    content: '\E80d';
}

.icon-14::before {
    font-family: 'MyFlutterAppAll';
    content: '\E80e';
}

.icon-15::before {
    font-family: 'MyFlutterAppAll';
    content: '\E83f';
}

.icon-16::before {
    font-family: 'MyFlutterAppAll';
    content: '\E80f';
}

.icon-17::before {
    font-family: 'MyFlutterAppAll';
    content: '\E810';
}

.icon-18::before {
    font-family: 'MyFlutterAppAll';
    content: '\E811';
}

.icon-19::before {
    font-family: 'MyFlutterAppAll';
    content: '\E812';
}

.icon-20::before {
    font-family: 'MyFlutterAppAll';
    content: '\E813';
}

.icon-21::before {
    font-family: 'MyFlutterAppAll';
    content: '\E814';
}

.icon-22::before {
    font-family: 'MyFlutterAppAll';
    content: '\E815';
}

.icon-23::before {
    font-family: 'MyFlutterAppAll';
    content: '\E816';
}

.icon-24::before {
    font-family: 'MyFlutterAppAll';
    content: '\E817';
}

.icon-25::before {
    font-family: 'MyFlutterAppAll';
    content: '\E845';
}

.icon-26::before {
    font-family: 'MyFlutterAppAll';
    content: '\E818';
}

.icon-27::before {
    font-family: 'MyFlutterAppAll';
    content: '\E819';
}

.icon-28::before {
    font-family: 'MyFlutterAppAll';
    content: '\E81a';
}

.icon-29::before {
    font-family: 'MyFlutterAppAll';
    content: '\E81b';
}

.icon-30::before {
    font-family: 'MyFlutterAppAll';
    content: '\E81c';
}

.icon-31::before {
    font-family: 'MyFlutterAppAll';
    content: '\E81d';
}

.icon-32::before {
    font-family: 'MyFlutterAppAll';
    content: '\E81e';
}

.icon-33::before {
    font-family: 'MyFlutterAppAll';
    content: '\E81f';
}

.icon-34::before {
    font-family: 'MyFlutterAppAll';
    content: '\E820';
}

.icon-35::before {
    font-family: 'MyFlutterAppAll';
    content: '\E821';
}

.icon-36::before {
    font-family: 'MyFlutterAppAll';
    content: '\E822';
}

.icon-37::before {
    font-family: 'MyFlutterAppAll';
    content: '\E823';
}

.icon-38::before {
    font-family: 'MyFlutterAppAll';
    content: '\E824';
}

.icon-39::before {
    font-family: 'MyFlutterAppAll';
    content: '\E825';
}

.icon-40::before {
    font-family: 'MyFlutterAppAll';
    content: '\E826';
}

.icon-41::before {
    font-family: 'MyFlutterAppAll';
    content: '\E827';
}

.icon-42::before {
    font-family: 'MyFlutterAppAll';
    content: '\E828';
}

.icon-43::before {
    font-family: 'MyFlutterAppAll';
    content: '\E829';
}

.icon-44::before {
    font-family: 'MyFlutterAppAll';
    content: '\E82a';
}

.icon-45::before {
    font-family: 'MyFlutterAppAll';
    content: '\E82b';
}

.icon-46::before {
    font-family: 'MyFlutterAppAll';
    content: '\E82c';
}

.icon-47::before {
    font-family: 'MyFlutterAppAll';
    content: '\E82d';
}

.icon-48::before {
    font-family: 'MyFlutterAppAll';
    content: '\E82e';
}

.icon-49::before {
    font-family: 'MyFlutterAppAll';
    content: '\E82f';
}

.icon-50::before {
    font-family: 'MyFlutterAppAll';
    content: '\E830';
}

.icon-51::before {
    font-family: 'MyFlutterAppAll';
    content: '\E831';
}

.icon-52::before {
    font-family: 'MyFlutterAppAll';
    content: '\E832';
}

.icon-53::before {
    font-family: 'MyFlutterAppAll';
    content: '\E833';
}

.icon-54::before {
    font-family: 'MyFlutterAppAll';
    content: '\E834';
}

.icon-55::before {
    font-family: 'MyFlutterAppAll';
    content: '\E835';
}

.icon-56::before {
    font-family: 'MyFlutterAppAll';
    content: '\E836';
}

.icon-57::before {
    font-family: 'MyFlutterAppAll';
    content: '\E837';
}

.icon-58::before {
    font-family: 'MyFlutterAppAll';
    content: '\E838';
}

.icon-59::before {
    font-family: 'MyFlutterAppAll';
    content: '\E839';
}

.icon-60::before {
    font-family: 'MyFlutterAppAll';
    content: '\E83a';
}

.icon-61::before {
    font-family: 'MyFlutterAppAll';
    content: '\E83b';
}

.icon-62::before {
    font-family: 'MyFlutterAppAll';
    content: '\E83c';
}

.icon-63::before {
    font-family: 'MyFlutterAppAll';
    content: '\E83d';
}

.icon-64::before {
    font-family: 'MyFlutterAppAll';
    content: '\E83e';
}

.icon-65::before {
    font-family: 'MyFlutterAppAll';
    content: '\E83f';
}

.icon-66::before {
    font-family: 'MyFlutterAppAll';
    content: '\E840';
}

.icon-67::before {
    font-family: 'MyFlutterAppAll';
    content: '\E841';
}

.icon-68::before {
    font-family: 'MyFlutterAppAll';
    content: '\E842';
}

.icon-69::before {
    font-family: 'MyFlutterAppAll';
    content: '\E843';
}

.icon-70::before {
    font-family: 'MyFlutterAppAll';
    content: '\E844';
}

.icon-71::before {
    font-family: 'MyFlutterAppAll';
    content: '\E845';
}

.icon-72::before {
    font-family: 'MyFlutterAppAll';
    content: '\E846';
}

.icon-73::before {
    font-family: 'MyFlutterAppAll';
    content: '\E847';
}

.icon-74::before {
    font-family: 'MyFlutterAppAll';
    content: '\E848';
}

.icon-75::before {
    font-family: 'MyFlutterAppAll';
    content: '\E849';
}

.icon-76::before {
    font-family: 'MyFlutterAppAll';
    content: '\E84a';
}

.icon-77::before {
    font-family: 'MyFlutterAppAll';
    content: '\E84b';
}

.icon-78::before {
    font-family: 'MyFlutterAppAll';
    content: '\E84c';
}

.icon-79::before {
    font-family: 'MyFlutterAppAll';
    content: '\E84d';
}

.icon-80::before {
    font-family: 'MyFlutterAppAll';
    content: '\E84e';
}

.icon-81::before {
    font-family: 'MyFlutterAppAll';
    content: '\E84f';
}

.icon-82::before {
    font-family: 'MyFlutterAppAll';
    content: '\E850';
}

.icon-83::before {
    font-family: 'MyFlutterAppAll';
    content: '\E851';
}

.icon-84::before {
    font-family: 'MyFlutterAppAll';
    content: '\E852';
}

.icon-85::before {
    font-family: 'MyFlutterAppAll';
    content: '\E853';
}

.icon-86::before {
    font-family: 'MyFlutterAppAll';
    content: '\E854';
}

.icon-87::before {
    font-family: 'MyFlutterAppAll';
    content: '\E855';
}

.icon-88::before {
    font-family: 'MyFlutterAppAll';
    content: '\E856';
}

.icon-89::before {
    font-family: 'MyFlutterAppAll';
    content: '\E857';
}

.icon-90::before {
    font-family: 'MyFlutterAppAll';
    content: '\E858';
}

.icon-91::before {
    font-family: 'MyFlutterAppAll';
    content: '\E859';
}

.icon-92::before {
    font-family: 'ExternalAssetIcons';
    content: '\E807';
}

.icon-93::before {
    font-family: 'ExternalAssetIcons';
    content: '\E808';
}

.icon-94::before {
    font-family: 'MaterialSymbolsOutlined';
    content: '\e227';
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}

.icon-95::before {
    font-family: 'MaterialSymbolsOutlined';
    content: '\e263';
}

.icon-96::before {
    font-family: 'MaterialSymbolsOutlined';
    content: '\e40a';
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}

/* this is not working */
.icon-97::before {
    font-family: 'MaterialSymbolsOutlined';
    content: '\e4c9';
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}

.icon-98::before {
    font-family: 'MaterialSymbolsOutlined';
    content: '\f10a';
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}

.icon-99::before {
    font-family: 'MaterialSymbolsOutlined';
    content: '\ead5';
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}

.icon-100::before {
    font-family: 'MaterialSymbolsOutlined';
    content: '\e9f9';
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}

.icon-101::before {
    font-family: 'MaterialSymbolsOutlined';
    content: '\ea52';
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}

.icon-102::before {
    font-family: 'MaterialSymbolsOutlined';
    content: '\ea58';
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}

.icon-103::before {
    font-family: 'MaterialSymbolsOutlined';
    content: '\eaae';
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}

.icon-104::before {
    font-family: 'MaterialSymbolsOutlined';
    content: '\eb3b';
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}

.icon-105::before {
    font-family: 'MaterialSymbolsOutlined';
    content: '\eb48';
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}

.icon-106::before {
    font-family: 'MaterialSymbolsOutlined';
    content: '\eb3e';
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}

.icon-107::before {
    font-family: 'MaterialSymbolsOutlined';
    content: '\eb45';
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}

.icon-108::before {
    font-family: 'MaterialSymbolsOutlined';
    content: '\ebcc';
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}

.icon-109::before {
    font-family: 'MaterialSymbolsOutlined';
    content: '\f039';
    font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}

.icon-110::before {
    font-family: 'MaterialSymbolsOutlined';
    content: '\e420';
    font-variation-settings: 'FILL' 0, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}


.splide {
    .splide__track {
        .splide__list {
            align-items: flex-start !important;
        }
    }
}

.splide__slide:not(.is-active) {
    height: 0 !important;
}