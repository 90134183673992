.navbarContainer {
    width: 100%;
    height: 90px;
    z-index: 1000;
    position: relative;
}

.pageNameContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    top: 30px;
    margin: 0 auto;
    left: 0;
    right: 0;

}

.pageName {
    font-size: 1.5rem;
    font-family: "Lato";
    color: rgb(255, 255, 255);
    line-height: 1.2;
}

.navbarContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    /* width: 1200px; */
    height: 100%;
}

.navbarLogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-left: 15px;
}

.logoText {
    font-size: 37.5px;
    font-family: "Lato";
    color: rgb(44, 157, 207);
    line-height: 1.2;
    text-align: center;
}

.signOutBtnContainer {
    border-width: 2px;
    border-color: rgb(255, 255, 255);
    border-style: solid;
    background-color: rgb(251, 0, 47);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
    width: 170px;
    height: 44px;
    border-radius: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 20px;
    font-family: "Lato";
    color: rgb(255, 255, 255);
    font-weight: 500;
    line-height: 2.667;
}

.hamburger {
    display: none;
}

.logout {
    width: 20px;
    display: none;
}

@media only screen and (max-width: 1200px) {
    .navbarContainer {
        /* height: 151px; */
        height: 92px;
    }

    .pageNameContainer {
        top: 80px;
    }

    .navbarContent {
        align-items: center;
    }

    .hamburger {
        display: block;
        transition: all 0.6s ease-in-out;
    }

    .selectedHamburger {
        transform: rotate(90deg);
    }
}

@media only screen and (max-width: 625px) {
    .navbarLogoContainer {
        flex-direction: column;
        gap: 1px;
    }

    .logo {
        width: 170px;
    }

    .logoText {
        font-size: 16px;
    }
}

@media only screen and (max-width: 469px) {
    .pageNameContainer {
        margin: 0 auto;
        width: fit-content;
        background-image: none;
        text-align: center;
        justify-content: space-between;
    }

    .pageName {
        font-size: 1.5rem;
        width: 100%;
    }

    .logout {
        display: block;
    }

    .logoutTxt {
        display: none;
    }

    .signOutBtnContainer {
        border-width: 1px;
        width: 44px;
        height: 44px;
    }

    .navbarLogoContainer {
        width: 40%;
        align-items: flex-start;
        /*align-items: flex-end;*/
        justify-content: center;
    }

    .logo {
        width: 150px;
    }

    .logoText {
        font-size: 16px;
        margin-right: 15%;
    }
}