.pricingSummaryContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
}

.contentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 60%;
    background-color: rgb(255, 255, 255);
}

.pricingSummaryHeader {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-left: 60px;
    margin-top: 40px;
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.logoText {
    font-size: 30px;
    font-family: "Lato";
    color: rgb(35, 159, 206);
    line-height: 0.616;
    margin-left: -25px;
}

.pricingSummaryBody {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    margin-top: 40px;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-right: 10px;
    margin-left: 10px;
}

.pricingSummaryForm {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    gap: 25px;
    width: 70%;
}


.userBadgeText {
    font-size: 20px;
    font-family: "Lato";
    color: rgb(0, 0, 0);
    font-weight: bold;
    line-height: 1.997;
}

.formInputContainer,
.formInputContainerMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 50%;
    height: 55px;

    font: normal normal bold 14px "Lato";
    /* border: 1px solid rgb(148, 153, 155); */
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;

    box-shadow: 1px 4px 8px 1px rgba(43, 43, 43, 0.35);
    padding-left: 12px;
    padding-right: 12px;
}

.formInputContainerMobile {
    width: 80%;
    justify-content: flex-start;
}

.formInputContainerCode {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    height: 55px;

    font: normal normal bold 14px "Lato";
    /* border: 1px solid rgb(148, 153, 155); */
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;

    box-shadow: 1px 4px 8px 1px rgba(43, 43, 43, 0.35);
    padding-left: 12px;
    padding-right: 12px;
}

.inputIcon {
    width: 22px;
    height: 22px;
    color: rgb(8, 138, 199);
}

.inputIconCountry {
    width: 40px;
    height: 25px;
}

.formRow {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}

.mobileRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}

.countryCode {
    color: rgb(0, 0, 0);
    font-family: "Lato";
    font-size: 20px;
    font-weight: bold;
}

.formInput,
.formInputMobile {
    border: none;
    color: rgb(0, 0, 0);
    font-family: "Lato";
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    /* width: 275px; */
}

.formInput:focus,
.formInputMobile:focus {
    outline: none;
}

.formInput::placeholder,
.formInputMobile::placeholder {
    color: rgb(148, 153, 155);
    font-family: "Lato";
    font-size: 18px;
    font-weight: bold;
}

.formInputMobile {
    width: 155px;
}

.pricingSummaryPolicyAcceptance {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    margin-top: 60px;
    margin-left: 250px;
}

.checkBox {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.acceptanceText {
    font-size: 16px;
    font-family: "Lato";
    color: rgb(0, 0, 0);
    font-weight: 600;
    line-height: 1.2;
    text-align: left;
}

.acceptanceTextMobile {
    display: none;
}

.link {
    color: #2c7ec0;
    cursor: pointer;
}

.link:hover {
    color: #09487b;
}

.bottomContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    margin-left: 300px;
    width: 100%;
    cursor: pointer;
}

.bottomContainer2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 50px;
    cursor: pointer;
    width: 90%;
}

.continueBtn,
.continueBtn2 {
    background-color: rgb(24, 147, 248);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 10px;
}

.continueBtn2 {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
}

.continueBtnText,
.continueBtnText2 {
    font-size: 26px;
    font-family: "Lato";
    color: rgb(255, 255, 255);
    font-weight: bold;
}

.continueBtnText2 {
    font-size: 20px;
}

.bottomContainer2 {
    display: none;
}

.summaryContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 40%;
    background-color: #f2f2f2;
    padding-left: 40px;
    padding-right: 40px;
    min-height: 100vh;
}

.summaryHeader {
    font-size: 32px;
    font-family: "Lato";
    color: rgb(115, 115, 115);
    font-weight: bold;
    text-align: left;
    width: 100%;
    margin-top: 120px;
}

.divider {
    border-width: 2.999px;
    border-color: rgb(199, 200, 199);
    border-style: solid;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
}

.summaryBody {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.bodyTopRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;
    width: 100%;
    margin-top: 10px;
}

.bodyTopRowText {
    font-size: 30px;
    font-family: "Lato";
    color: rgb(27, 23, 13);
    font-weight: 800;
}

.bodyrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

.subtitle {
    font-size: 18px;
    font-family: "Lato";
    color: rgb(27, 23, 13);
    font-weight: bold;
    text-align: left;
}

.summaryFooterRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    margin-top: 10px;
    margin-bottom: 20px;
}

.total {
    font-size: 24px;
    font-family: "Lato";
    color: rgb(27, 23, 13);
    font-weight: bold;
}

.logoContainer2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    display: none;
}

@media only screen and (max-width: 1260px) {
    .userBadgeContainer {
        width: 180px;
    }

    .pricingSummaryForm {
        width: 85%;
    }
}

@media only screen and (max-width: 950px) {
    .pricingSummaryContainer {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .pricingSummaryHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-left: 0px;
        margin-top: 40px;
    }

    .contentContainer {
        width: 100%;
    }

    .summaryContainer {
        min-height: 100vh;
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 60px;
    }

    .summaryHeader {
        margin-top: 60px;
    }

    .summaryHeader,
    .divider,
    .summaryBody {
        width: 90%;
    }

    .summaryFooterRow {
        width: 85%;
    }

    .bottomContainer {
        display: none;
    }

    .bottomContainer2 {
        display: flex;
    }

    .pricingSummaryBody {
        padding: 0px;
    }

    .bottomContainer {
        justify-content: center;
        margin-left: 0px;
    }

    .pricingSummaryPolicyAcceptance {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 60px;
        margin-left: 0px;
    }

    .acceptanceTextMobile {
        display: block;
        font-size: 16px;
        font-family: "Lato";
        color: rgb(0, 0, 0);
        font-weight: 600;
        line-height: 1.2;
        text-align: left;
        width: 80%;
    }

    .acceptanceText {
        display: none;
    }
}

@media only screen and (max-width: 600px) {

    .summaryContainer,
    .summaryHeader {
        margin-top: 30px;
    }

    .pricingSummaryForm {
        width: 95%;
        gap: 15px;
    }

    .formInputContainer,
    .formInputContainerMobile,
    .formInputContainerCode {
        gap: 10px;
        height: 40px;

        font: normal normal bold 8px "Lato";
        border-radius: 4px;
        background-color: rgb(255, 255, 255);
        box-shadow: 1px 2px 4px 1px rgba(43, 43, 43, 0.35);
    }

    .formInput,
    .formInputMobile {
        font-size: 14px;
    }

    .formInput::placeholder,
    .formInputMobile::placeholder {
        font-size: 14px;
    }

    .formRow,
    .mobileRow {
        gap: 10px;
    }
}

@media only screen and (max-width: 485px) {
    .bodyTopRow {
        flex-direction: column;
        gap: 20px;
    }

    .formInput,
    .formInputMobile {
        width: 100%;
    }

    .bottomContainer2,
    .continueBtn2 {
        width: 90vw;
    }

    .total {
        font-size: 20px;
        font-family: "Lato";
        color: rgb(27, 23, 13);
        font-weight: bold;
    }
}

.packageTagFree {
    background: linear-gradient(180deg, rgb(0, 206, 82) 0%, rgb(4, 232, 4) 100%);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
    width: 162px;
    height: 44px;
    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    /* font-size: 33.383px; */
    font-size: 20px;
    font-family: "Lato";
    color: rgb(255, 255, 255);
    font-weight: 600;
}

.packageTagPro {
    background: linear-gradient(180deg,
            rgb(174, 134, 37) 0%,
            rgb(247, 239, 138) 30%,
            rgb(210, 172, 71) 58%,
            rgb(237, 201, 103) 100%);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
    width: 162px;
    height: 44px;
    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    /* font-size: 33.383px; */
    font-size: 25px;
    font-family: "Lato";
    color: rgb(0, 0, 0);
    font-weight: 600;
}


.badgeIcon {
    margin-left: 5px;
}