.plus-icon {
    margin-left: 27%;
    margin-top: 10px;
    font-weight: 800;
}

.add .form-control {
    border-radius: 30px;
    margin-left: 5px;
}

.add {
    margin: auto;
}

.Form {
    margin-top: 15px;
    padding: 50px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 4px 4px, rgba(0, 0, 0, 0.11) 0px 1px 1px;
    border-radius: 10px;
}


.file-upload2 {
    height: 100px;
    width: 100px;
    margin: 40px auto;
    overflow: hidden;
    position: relative;

}

.file-upload2 input {
    position: absolute;
    height: 400px;
    width: 400px;
    left: -200px;
    top: -200px;
    background: transparent;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
}

.file-upload2 .fileuploadIcon2 {
    font-size: 60px;
    background-color: white;
    padding: 1px;

    text-align: center;
}

.post-image {
    width: 25%;
}

.add_peferred_partner {
    padding: 8px;
}

@media only screen and (max-width: 800px) {
    .plus-icon {
        margin-left: 87%;
    }

    .file-upload2 {
        width: 100%;
        margin: auto;
    }

    .post-image {
        width: auto;
    }
}