.sec5_responsive {
    display: none;
}

.sec7_res {
    display: none;
}



/* SECTION 1 START  */
.sec_1_outer {
    width: 100%;
    background-image: url(../../public/static/referrel_one/section1BackGround.png);
    background-color: #000;
    background-size: cover;
    background-repeat: no-repeat;
}

.sec_1_title_outer {
    width: 100%;
    text-align: center;
    font-family: 'BebasNeueBold';
    font-size: 40px;
    letter-spacing: 2px;
    font-weight: 500;
}

.sec_1_title1 {
    padding-top: 20px;
    color: white;
    font-style: italic;
}

.sec_1_title2 {
    width: 100%;
    color: #29abe2;
    background-color: white;
    font-style: italic;
}

.sec_1_title2 p {
    text-indent: 5px !important;
}

.sec_1_title2 p span {
    color: #f88e07;
}

.sec1_side1,
.sec1_side2 {
    font-family: 'BebasNeueRegular';
    font-size: 50px;
    writing-mode: vertical-rl;
    white-space: nowrap;
    text-orientation: upright;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec1_side1 {
    color: #29abe2;
}

.sec1_side2 {
    color: #f88e07;
}

.sec1_center {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.sec1_center_heaading {
    font-family: 'BebasNeueRegular';
    color: #29abe2;
    text-align: center;
}

.sec1_center_heaading span {
    color: #f88e07;
}

.sec1_center_video {
    display: flex;
    justify-content: center;
    border-radius: 10px;
}

.sec1_center_video video {
    width: 80%;
    border-radius: 10px;
}

.sec_1_video_title {
    font-family: 'BebasNeueRegular';
    display: flex;
    justify-content: center;
}

.sec_1_video_title div {
    font-size: 30px;
    color: #29abe2;
    padding: 20px 40px;
    border-radius: 0 70px 0 100px;
    background-color: #fff;
    width: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.sec_1_video_title span {
    color: #f88e07;
}

/* SECTION 1 END  */

/* SECTION-2 START */
.sec2_outer {
    width: 100%;
    background-image: url(../../public/static/referrel_one/section2BackGround.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.sec2_title {
    font-family: 'BebasNeueBold';
    padding-top: 30px;
    text-align: center;
}

.sec2_title p span {
    color: #a52b30;
}

.sec2_sub_title {
    text-align: center;
}

.sec2_sub_title p {
    text-align: center;
    padding: 0 20px;
    font-family: "BebasNeueThin";
}

.sec2_sub_content {
    font-family: "BebasNeueRegular";
    padding: 0 20px;
}

.sec2_footer_outer {
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec2_footer_part_1_outer {
    display: flex;
    height: 180px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec2_footer_part_1 {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
}

.sec2_line {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.sec2_line div {
    height: 15px;
    background: linear-gradient(to right, rgb(255, 255, 255), #37d936);
    width: 100%;
}


.sec2_button {
    margin-top: 10px;
    height: 60px;
    width: 80%;
    border-radius: 40px;
    background-image: url(../../public/static/referrel_one/sec_e_button.png);
    background-position: center right;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec2_button p {
    font-size: 50px;
    letter-spacing: 2px;
    color: #fff;
    text-shadow: #000 1px 1px;
    font-family: 'BebasNeueRegular';
}

.sec2_footer_part_3 {
    display: flex;
    align-items: center;
}

.sec2_footer_arrow_img {
    height: 80px;
    width: 100%;
}

.sec2_footer_arrow_img img {
    width: 100%;
    height: 100%;
}

.sec2_footer_part_3_content {
    font-family: 'BebasNeueRegular';
    width: 50%;
    text-align: center;
    font-size: 50px;
}

.sec2_footer_part_3_content span {
    font-family: "BebasNeueThin";
}

.sec2_footer_part_3_img {
    width: 40%;
}

.sec2_footer_part_3_img img {
    height: 100%;
    width: 100%;
}

/* SECTION-2 END */

/* SECTION-3 START */
.sec3 {
    /* height: 100vh; */
    width: 100%;
}

.sec3_part_1 {
    min-height: 700px;
    width: 100%;
    background-color: #000;
}

.sec3_part_1_content {
    font-family: 'BebasNeueBold';
    padding-top: 35px;
    min-height: 20%;
    width: 100%;
    color: white;
    text-align: center;
}

.sec3_part_1_content span {
    color: #ff8a00;
}

.sec3_part_1_img {
    height: 100%;
    width: 100%;
    background-image: url("../../public/static/referrel_one/Page3/sec3_rocker_image.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.sec3_part_2 {
    min-height: 100vh;
    width: 100%;
    padding: 40px 20px 0 20px;
}

.sec3_part_2_title {
    font-family: 'BebasNeueBold';
    text-align: center;
}

.sec3_part_2_content {
    font-family: "BebasNeueRegular";
    word-spacing: 3px;
}

.sec3_button {
    /* padding-top: 15px; */
}

.sec3_button_div {
    margin-top: 10px;
    height: 60px;
    border-radius: 40px;
    background-image: url("../../public/static/referrel_one/Page3/sec_3_button.png");
    background-position: center right;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec3_button_div p {
    font-size: 50px;
    letter-spacing: 2px;
    color: #fff;
    text-shadow: #000 1px 1px;
    font-family: 'BebasNeueRegular';
}

.sec3_button_part_1 {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec3_button_part_1 div {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-image: url("../../public/static/referrel_one/Page3/sec_3_arrow.png");
    background-size: contain;
    background-position: center right;
    margin-right: 10px;
}

.sec3_button {
    margin-top: 10px;
    height: 60px;
    border-radius: 40px;
    background-image: url("../../public/static/referrel_one/Page3/sec_3_button.png");
    background-position: center right;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec3_button p {
    font-size: 50px;
    letter-spacing: 2px;
    color: #fff;
    text-shadow: #000 1px 1px;
    font-family: 'BebasNeueRegular';
}

/* SECTION-3 END*/

/* SECTION-4 START */
.sec4 {
    /* height: 100vh; */
    width: 100%;
    text-align: center;
    padding: 40px 0px 0 0px;
    background-image: url("../../public/static/referrel_one/Page4/sec4_backgrund.png");
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
}

.sec4 span {
    color: #6ee2c1;
}

.sec4_bold_2 {
    font-family: "BebasNeueRegular";
    font-weight: 500 !important;
}

.sec4_bold_1 {
    font-family: 'BebasNeueBold';
}

.sec4_bold_3 {
    font-family: "BebasNeueThin";
}

/* SECTION-4 END */

/* SECTION-5 START  */
.sec5 {
    position: relative;
    width: 100%;
}

.sec5_img {
    height: 100%;
    width: 100%;
}

.sec5_img img {
    height: 100%;
    width: 100%;
}

.sec5_get_knocard_bg {
    background-image: url('../../public/static/referrel_one/Page5/sec5_res_background.png');
    position: relative;
}


.sec_footer {
    position: relative;
    left: 0;
    bottom: 10%;
}

.sec5_button {
    margin-top: 10px;
    height: 60px;
    border-radius: 40px;
    background-image: url("../../public/static/referrel_one/Page5/sec5_button.png");
    background-position: center right;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec5_button p {
    font-size: 50px;
    letter-spacing: 2px;
    color: #fff;
    text-shadow: #000 1px 1px;
    font-family: 'BebasNeueRegular';
}

/* SECTION-5 END */

/* SECTION-6 START  */
.sec6 {

    width: 100%;
    background-image: url("../../public/static/referrel_one/Page6/sec6_background.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.sec6_title {
    font-family: 'BebasNeueThin';
    padding: 80px 20px 80px 20px;
    text-align: center;
}

.sec6_content {
    font-family: "BebasNeueRegular";
    padding-bottom: 20px;
}

.sec6_footer {
    text-align: center;
}

.sec6_footer p {
    font-family: "BebasNeueRegular";
    color: #fff;
}

.sec6_footer span {
    color: #000;
}

.sec6_footer_title_1 {
    padding-top: 50px;
}

.sec6_bold {
    font-family: "BebasNeueBold" !important;
}


.sec6_button_outer {
    padding-top: 50px;
}

.sec6_button {
    width: 100%;
    height: 75px;
    border-radius: 43px;
    background-image: url("../../public/static/referrel_one/Page6/sec6_button.png");
    background-position: center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec6_button p {
    font-family: 'BebasNeueRegular';
    font-size: 50px;
    letter-spacing: 2px;
    color: #fff;
    text-shadow: #000 1px 1px;
}

/* SECTION-6 END    */

.sec7 {
    height: 100vh;
    width: 100%;
    background-image: url("../../public/static/referrel_one/Page7/sec7_background.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.sec7_contet {
    font-family: 'BebasNeueRegular';
    padding-top: 45px;
    font-size: 60px;
    text-align: center;
    color: #fff;
}

.sec7_contet span {
    color: #f88e07;
}

.sec7_responsive {
    display: none;
}

.sec8 {

    width: 100%;
}

.sec8_side_1 {
    width: 100%;
    background-image: url("../../public/static/referrel_one/Page8/sec8_side2.png");
}

.sec8_side_1 img {
    width: 100%;
    height: 100%;
}

.sec8_side_2 {
    padding: 20px;
    width: 100%;
    background-image: url("../../public/static/referrel_one/Page8/sec8_side2.png");
    background-size: contain;
}

.sec8_side_2_title p {
    font-size: 50px;
    color: #ff8a00;
    font-family: "BebasNeueRegular";
}

.sec8_side_2_title span {
    font-family: "BebasNeueRegular";
    font-size: 30px;
}

.sec8_side_2_des {
    padding: 40px 0;
    color: white;
    text-align: center;
    font-size: 30px;
    font-family: "BebasNeueBold";
}

.sec8_side_2_step_title {
    color: #f88e07;
    font-size: 30px;
    font-family: "BebasNeueRegular";
}

.sec8_side_2_steps {
    font-family: "BebasNeueRegular";
    padding-top: 30px;
    padding-left: 20px;
    padding-bottom: 100px;
    color: #fff;
}

/* .sec8_button_part_1 {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .sec8_button_part_1 div {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center right;
  }
  
  .sec8_button {
    height: 80px;
    border-radius: 40px;
    background-position: center right;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .sec8_button p {
    font-size: 50px;
    letter-spacing: 2px;
    color: #fff;
    text-shadow: #000 1px 1px;
    font-family: 'BebasNeueRegular';
  } */

.sec8_button_part_1 {
    height: 80px;
    /* padding-lft: 40px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec8_button_part_1 div {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-image: url("../../public/static/referrel_one/Page8/sec8_left_arrow.png");
    background-size: contain;
    background-position: center right;
    margin-right: 10px;
}

.sec8_button {
    margin-top: 10px;
    height: 60px;
    border-radius: 40px;
    background-image: url("../../public/static/referrel_one/Page8/sec8_button.png");
    background-position: center right;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec8_button p {
    font-size: 50px;
    letter-spacing: 2px;
    color: #fff;
    text-shadow: #000 1px 1px;
    font-family: 'BebasNeueRegular';
}


.sec9 {
    padding-top: 40px;
    padding: 40px 20px 0 20px;
    background-image: url('../../public/static/referrel_one/Page8_1/sec9_background.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.sec9_part_1 {
    width: 100%;
    /* background-image: url('../../public/static/referrel_one/Page8_1/Superman_KnoCard_-_V.png'); */
    background-size: contain;
    background-repeat: no-repeat;
}

.sec9_part_2_title {
    font-size: 50px;
    color: #003E4F;
    padding-bottom: 20px;
    text-align: center;
    font-family: 'BebasNeueRegular';

}

.sec9_part_2_title span {
    color: #fff;
    font-family: 'BebasNeueRegular';
}

.sec9_side_2_des {
    padding: 40px 0;
    color: white;
    text-align: center;
    font-family: "BebasNeueBold";
}

.sec9_side_2_subtitle {
    font-size: 25px;
    font-family: "BebasNeueRegular";
    color: #fff;
    padding-bottom: 15px;
}

.sec9_side2_steps {
    font-family: "BebasNeueRegular";
    padding-bottom: 100px;

    color: #fff;
}

.sec9_button_part_1 {
    height: 80px;
    /* padding-left: 40px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec9_button_part_1 div {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-image: url("../../public/static/referrel_one/Page8_1/sec9_leftarrow.png");
    background-size: contain;
    background-position: center right;
    margin-right: 10px;
}

.sec9_button {
    margin-top: 10px;
    height: 60px;
    border-radius: 40px;
    background-image: url("../../public/static/referrel_one/Page8_1/sec9_button.png");
    background-position: center right;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec9_button p {
    font-size: 50px;
    letter-spacing: 2px;
    color: #fff;
    text-shadow: #000 1px 1px;
    font-family: 'BebasNeueRegular';
}


@media only screen and (max-width: 1440px) {


    .sec_1_title2 {
        font-size: 30px;
    }


    .sec_1_video_title div {
        padding: 10px;
        font-size: 20px;
    }

    .sec1_side2,
    .sec1_side1 {
        font-size: 35px;
    }

    /* SECTIION-2  */
    .sec2_outer {
        /* padding: 0 10px; */
    }


    .sec2_sub_title {
        padding: 40px 0;
    }



    .sec2_button {
        font-size: 35px;
    }

    .sec2_footer_part_3_content {
        font-size: 40px;
    }

    /* SECTION 3 */
    .sec3 {
        padding-top: 100px;
    }

    /* SECTION 6 */
    .sec6 {
        /* height: 100%; */
        /* padding: 0 10px; */
    }

    .sec7 {
        display: none;
    }

    .sec7_res {
        /* padding: 0 10px; */
        display: block;
        width: 100%;
        background-image: url('../../public/static/referrel_one/Page7/sec7_background_res.png');
        background-position: center center;
        background-size: cover
    }

    .sec7_responsive_contet span {
        color: #f88e07;

    }


    .sec7_responsive_contet {
        font-family: 'BebasNeueBold';
        text-transform: lowercase !important;
        padding-top: 20px;
        text-align: center;
        font-size: 40px;
        color: white;
    }

    .sec7_box_outer {
        padding: 10px;
    }

    .sec7_box {
        border: 1px solid #ff8a00;
        padding: 20px;
        text-align: center;
    }

    .sec7_box_title {
        font-size: 35px;
        color: #f88e07;
        font-family: 'BebasNeueRegular';
    }

    .sec7_box_description {
        font-family: 'BebasNeueRegular';
        font-size: 23px;
        color: white;
    }

}

@media only screen and (max-width: 1300px) {


    .sec3_button_div {
        height: 8px;

    }

    .sec3_button_div p {
        font-size: 4px;
    }

    .sec6_title {
        padding: 50px 0;
    }
}

@media only screen and (max-width: 1200px) {

    .sec2_footer_part_1_outer {
        height: 150px;
    }

    .sec2_footer_part_3 {}

    .sec2_button p {
        font-size: 30px;
    }

    .sec3_button_div p {
        font-size: 30px;
    }

    .sec_non_res {
        display: none;
    }

    .sec5_responsive {
        /* padding: 0 10px; */
        /* height: 100%; */
        width: 100%;
        display: block;
        background-image: url('../../public/static/referrel_one/Page5/sec5_res_background.png');
        position: relative;
    }

    .hero_knocard_logo {
        position: absolute;
        right: 0;
        top: 15%;
        /* background-image: url('../../public/static/referrel_one/Page5/knocard_logo.png'); */
        background-position: center center;
        background-size: cover;
        width: 200px;
        height: 200px;
        z-index: 1;
        display: none;
    }

    .hero_knocard_logo img {
        height: 100%;
        width: 100%;
    }

    .sec5_responsive_title {
        font-size: 150px;
        background: linear-gradient(to top, #33333300 0%, #08a9f3 100%);
        font-family: 'BebasNeueBook';
        letter-spacing: 3px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        z-index: 99;
    }

    .sec5_res_box_outer {
        border: 1px solid #29abe2;
        text-align: center;
        padding: 10px;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: bottom;
        flex-direction: column;
    }

    .sec5_responsive_des1 {
        padding-bottom: 10px;
        color: white;
        z-index: 99;
        font-family: 'BebasNeueRegular';

    }

    .sec_res_subtitle {
        font-size: 30px;
        color: #29abe2;
        font-family: 'BebasNeueRegular';
    }

    .sec_res_sub_des {
        font-family: 'BebasNeueRegular';
        font-size: 1.5em;
        color: white;
    }

    .sec_res_img_outer {
        display: flex;
        justify-content: center;
        padding-top: 10px;
    }

    .sec_res_img {
        width: 65px;
        height: 55px;
    }

    .sec_res_img img {
        /*height: 100%;
      width: 100%;*/
    }

    /* SECTION 7 */


}

@media only screen and (max-width: 1030px) {

    /* SECTION 1 */
    /* .sec_1_title1 {
        font-size: 20px;
    } */

    .sec_1_title2 {
        font-size: 20px;
        font-style: italic;
    }


    .sec_1_video_title div {
        padding: 10px;
        font-size: 15px;
    }

    .sec1_side2,
    .sec1_side1 {
        font-size: 30px;
    }

    .sec1_center_heaading {
        padding-top: 0;
    }

    .sec5_responsive_title {
        padding-top: 15px;
        font-size: 12px;
    }

    .sec7_responsive_contet {
        font-size: 35px;
    }

    /* SECTIION-2  */


    .sec2_sub_title {
        padding: 40px 0;
    }


    .sec2_button p {
        font-size: 25px;
    }

    .sec2_footer_part_3_content {
        font-size: 4px;
    }

    .sec3 {}

    .sec_1_outer {
        display: block;
        width: 100%;
    }

    .sec2_outer {
        display: block;
        width: 100%;
    }

    .sec3 {
        width: 100%;
    }

}

@media only screen and (max-width: 899px) {

    /* SECTION 1 */
    .sec_1_title2 {
        font-size: 15px;
    }

    /* SECTIION-2  */



    .sec2_sub_title {
        padding: 40px 0;
    }



    .sec2_button {
        width: 100%;
        /* padding: 6px; */
    }

    .sec2_button p {
        font-size: 18px;
    }

    .sec2_footer_part_3_content {
        font-size: 30px;
    }

    .sec5_responsive_title {
        font-size: 80px;
    }

    .sec7_res {
        height: 100%;
    }
}

@media only screen and (max-width: 801px) {
    .sec2_button {
        font-size: 25px;
    }

    .sec6_title {
        padding-bottom: 30px;
    }

    .sec6_content {
        padding-bottom: 5px;
    }




}

@media only screen and (max-width: 701px) {
    .sec2_button {
        font-size: 25px;
    }

    .sec5_responsive_title {
        font-size: 80px;
    }
}

@media only screen and (max-width: 650px) {

    /* SECTION 1 */
    .sec_1_title1 {
        padding-top: 5px;
        /* font-size: 13px; */
    }

    .sec_1_title2 {
        padding-top: 5px;
        font-size: 13px;
    }

    .sec_1_video_title div {
        font-size: 12px;
    }

    /* SECTION 2 */
    .sec2_outer {
        /* padding: 0 10px; */
    }



    .sec2_sub_title {
        padding: 40px 0;
    }



    .sec2_button p {
        font-size: 30px;
        padding-bottom: 0;
    }

    .sec2_footer_part_3 {
        height: 80px;
    }

    .sec2_footer_part_3_content {
        font-size: 30px;
    }

    .sec6_button p {
        font-size: 30px;
    }

    .sec9_button p {
        font-size: 30px;
    }

    .sec5_button p {
        font-size: 35px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .sec3_button p {
        font-size: 25px;
    }
}

@media only screen and (max-width: 601px) {

    /* SECTION 2 */
    .sec2_outer {
        /* padding: 0 10px; */
    }


    .sec2_sub_title {
        padding: 4px 0;
    }

    .sec2_sub_content {

        padding-bottom: 20px;
    }

    .sec2_button {
        font-size: 25px;
    }

    .sec2_footer_part_3_content {
        font-size: 30px;
    }

    .sec5_responsive_title {
        font-size: 50px;
    }

    .sec9_button p {
        font-size: 30px;
    }

}

@media only screen and (max-width: 500px) {
    .sec6_title {
        padding-bottom: 30px;
    }

    .sec6_content {
        padding-bottom: 5px;
    }






    .sec7_responsive_contet {
        font-size: 25px;
    }

    .sec3_button p {
        font-size: 20px;
    }
}

@media only screen and (max-width: 480px) {



    .sec_1_title1 {
        padding-top: 5px;
        /* font-size: 13px; */
    }

    .sec_1_title2 {
        font-size: 13px;
    }

    .sec_1_video_title div {
        font-size: 10px;
        width: 90%;
    }

    /* SECTION 2 */
    .sec2_outer {
        /*  padding: 0 10px; */
        padding-bottom: 20px;
    }

    .sec2_sub_content {
        padding-bottom: 20px;
    }

    .sec2_button p {
        font-size: 25px;
    }

    .sec2_footer_part_3_content {
        font-size: 30px;
    }

    .sec3 {
        /* display: none; */
        padding-top: 60px;
    }

    .sec5_responsive_title {
        font-size: 40px;
    }

    .sec7_responsive_contet {
        font-size: 20px;
    }

}

@media only screen and (max-width: 480px) {

    /* SECTION 2 */
    .sec2_outer {
        /*padding: 0 10px;*/
    }

    .sec2_button p {
        font-size: 20px;
    }

    .sec3_button_div {
        height: 60px;
    }

    .sec3_button_div p {
        font-size: 13px !important;
    }

    .sec_button_part1 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sec3_part_2_title {
        padding-bottom: 40px;
        padding-top: 0;
    }

    .sec8_button_part_1 div {
        height: 50px;
    }

    .sec8_button {
        width: 100%;
    }

    .sec8_button p {
        font-size: 20px;
        font-weight: bold;
    }

    .sec9_button p {
        font-size: 25px;
    }

    .sec9_side_2_subtitle {
        font-size: 25px;
    }

    .sec9_part_2_title {
        font-size: 40px;
        padding-bottom: 0;
    }

    .sec9_button_part_1 div {
        height: 50px;
    }

    .sec9_button {
        width: 100%;
    }

}

@media only screen and (max-width: 365px) {

    /* SECTION 2 */
    .sec2_button {
        font-size: 20px;
    }

    .sec3_button_div {
        height: 60px;
    }

    .sec3_button_div p {
        font-size: 13px !important;
    }

    .sec5_button p {
        font-size: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .sec6 {
        padding-top: 10px;
    }

    .sec6_title {
        padding-top: 10px;
        padding-bottom: 30px;
    }

    .sec6_content {
        padding-bottom: 5px;
    }


    .sec6_button p {
        font-size: 20px;
    }



    .sec9_part_2_title {
        font-size: 30px;
    }

    .sec9_button p {
        font-size: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }


    .sec9_side_2_subtitle {
        font-size: 25px;
    }
}

@media only screen and (max-width: 310px) {

    /* SECTION 2 */
    .sec2_button {
        font-size: 15px;
    }
}

@media only screen and (max-width: 250px) {

    /* SECTION 2 */
    .sec2_button {
        font-size: 10px;
    }

    .sec6_button p {
        font-size: 15px;
    }
}