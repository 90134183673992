.dashboardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    width: 100%;
}

.dashboardContent {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    width: 97%;
    height: 100%;
    margin-bottom: 30px;
}


.userBadgeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    cursor: pointer;
    border-radius: 30px;

    background: linear-gradient(180deg,
            rgb(174, 134, 37) 0%,
            rgb(247, 239, 138) 30%,
            rgb(210, 172, 71) 58%,
            rgb(237, 201, 103) 100%);

    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
    width: 142px;
    height: 40px;
    margin-bottom: 20px;
}

.userBadgeText {
    font-size: 20px;
    font-family: "Lato";
    color: rgb(0, 0, 0);
    font-weight: bold;
    line-height: 1.997;
}

.mainContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    margin-left: 20px;
    margin-right: 5px;
    width: 100%;
    height: 100%;
}

.topBtnsContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 60px;
    margin: 0 auto;
    left: 0;
    right: 0;
    border-radius: 10px;
    background-color: rgb(8, 138, 198);
    box-shadow: 0px 4px 5.04px 0.96px rgba(0, 0, 0, 0.28);
    width: 306px;
    height: 70px;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
}

.viewOptionContainer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 50%;
    height: 100%;
    margin-bottom: 9%;
    padding-bottom: 5%;
}

.viewOptionActive {
    border-bottom: 2px solid rgb(255, 255, 255);
}

.viewOptionContainer:hover {
    background-color: rgb(6, 156, 226);
}

.topRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.helpVideosBtnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 20px;
    border-radius: 10px;

    gap: 40px;
    background-color: #f68a30;
}

.btnText {
    font-size: 25px;
    font-family: "Lato";
    color: rgb(255, 255, 255);
    line-height: 1.2;
}

.mediaContainerGrid {
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 5px;
    /* width: 100%; */
    justify-content: center;
}

.mediaElementAdd {
    border-radius: 10px;
    background-color: rgb(0, 130, 194);
    /* width: 337px; */
    height: 300px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
    /* padding: 10%; */
    cursor: pointer;
    /* gap: 30px; */
}

.addMediaText {
    font-size: 25px;
    font-family: "Lato";
    color: rgb(255, 255, 255);
    font-weight: bold;
    text-align: center;
}

.mediaElementAdd:hover {
    background-color: #EEF1F7;
}

.mediaContainer {
    overflow: hidden;
    transition: transform 0.3s ease;
}

.mediaContainer:hover {
    background-color: #3B3838;
    border-radius: 10px;
    /* position: relative; */
    margin: 1px;
    padding: 5px;
    transform: scale(1.01);
}

.mediaElement {
    /* border-radius: 19px; */
    /* background-color: rgb(0, 130, 194); */

    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    min-height: 250px;
}


@media only screen and (max-width: 1377px) {
    .sideBar {
        display: none;
    }

    .mainContent {
        width: 100%;
        /* padding-top: 30px; */
        padding-bottom: 30px;
        margin: 0;
    }

    .topBtnsContainer {
        top: 0px;
        border-radius: 10px;
        background-color: rgb(8, 138, 198);
        box-shadow: 0px 4px 5.04px 0.96px rgba(0, 0, 0, 0.28);
        width: 100%;
        height: 100%;
        padding-left: 0px;
        padding-right: 0px;
        cursor: pointer;
        position: relative;
    }

    .viewOptionContainer {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 70px;
        height: 100%;
        margin-bottom: 2%;
        padding-top: 3%;
        padding-bottom: 1%;
    }

    .dashboardContent {
        width: 100%;
    }

    .mediaContainerGrid {
        width: 95%;
        margin: 0 2.5%;
    }
}

@media only screen and (max-width: 850px) {
    .mediaContainerGrid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: 15px 15px;
        width: 95%;
        margin: 0 2.5%;
    }

    .mediaElement,
    .mediaElementAdd {
        max-height: 210px;
    }
}



.businessMediaElementAdd {
    border-radius: 12px;
    background-color: rgb(0, 130, 194);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.businessMediaElementAdd:hover {
    background-color: rgb(6, 156, 226);
}