* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.section_1_outer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 0px 15px;
    width: 100%;
    background-image: url(../../public/static/referrel_two/Section1/section_1_background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.sec1_get_engage {
    background: linear-gradient(0deg, rgba(2, 37, 56, 1) -10%, rgb(12, 142, 202) 100%);
}

.section_2_outer {
    background-image: url(../../public/static/referrel_two/Section2/section_2_background.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px 10px;
}

.section_3_outer {
    width: 100%;
    background-image: url(../../public/static/referrel_two/Section3/section_3_bacgroud.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.section_4_outer {
    /* min-height: 100vh; */
    background-image: url(../../public/static/referrel_two/Section4/sectin4_background.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.section_5_outer {
    min-height: 100vh;
    background-image: url(../../public/static/referrel_two/Section5/section_5_outer_background.png);
}

.section_5 {
    min-height: 100vh;
    background-image: url(../../public/static/referrel_two/Section5/section_5_background.png);
}

.section_6_outer {
    min-height: 100vh;
    background-image: url(../../public/static/referrel_two/Section6/Section6_background.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.sec6_face_label {
    background-image: url(../../public/static/referrel_two/Section6/white_line.png);
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
}

.sec6_face_label_contet1 {
    padding: 20px 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 0, 0, 1) 34%, rgba(255, 0, 0, 1) 46%, rgba(255, 165, 0, 1) 95%);
}

.sec6_face_label_contet2 {
    padding: 20px 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 165, 0, 1) 34%, rgba(255, 255, 0, 1) 78%);
}

.sec6_face_label_contet3 {
    padding: 20px 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(243, 245, 14, 1) 3%, rgba(0, 255, 68, 1) 55%);
}

.section_7_outer {
    /* min-height: 100vh; */
    width: 100%;
    background-image: url(../../public/static/referrel_two/Section7/section_7_background.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.section_8_outer {
    min-height: 100vh;
    width: 100%;
    background-image: url(../../public/static/referrel_two/Section8/Section8_background.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.section_8_part_1 {
    width: 100%;
    height: 80vh;
    background-image: url(../../public/static/referrel_two/Section8/section_8_part_1_background.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.section_8_part_2_outer {
    width: 100%;
    padding: 14px;
    background-image: url(../../public/static/referrel_two/Section8/formBorder.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.section_8_part_2 {
    width: 100%;
    height: 100%;
    background-image: url(../../public//static/referrel_two/Section8/form.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.section_8_part_3 {
    width: 100%;
    height: 80vh;
}

.section_8_part_3_side_1 {
    background-repeat: no-repeat;
    background-position: center center;
    height: 80vh;
    background-image: url(../../public/static/referrel_two/Section8/horizontal_line.png);
    background-size: contain
}

.section_8_part_3_side_2 {
    background-repeat: no-repeat;
    background-position: center center;
    height: 80vh;
    background-position: 0 0;
    background-image: url(../../public/static/referrel_two/Section8/light_line.png);
    background-size: contain;
}

.section_8_part_3_vertical_line {
    height: 6px;
    background-image: url(../../public/static/referrel_two/Section8/horizontal_line.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.section_8_part_3_vertical_line_dot {
    border-radius: 50%;
    width: 14%;
    background-image: url(../../public/static/referrel_two/Section8/dark_dot.png);
    background-position: center center;
    background-size: cover;
}

.section_8_future {
    background-image: url(../../public/static/referrel_two/Section8/Section8_background.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.section_9_outer {
    background-image: url('../../public/static/referrel_two/Section9/section9_background.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.section_10_outer {
    background-image: url(../../public/static/referrel_two/Section10/section_10_background.png);
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.section_11_outer {
    background-image: url(../../public/static/referrel_two/Section11/section11_background.png);
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.section_12_outer {
    min-height: 100vh;
    width: 100%;
    background-image: url(../../public/static/referrel_two/Section12/section12_background.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.section_12_overlay {
    min-height: 100vh;
    width: 100%;
    background-image: url(../../public/static/referrel_two/Section12/section12_background_overlay.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}



@media only screen and (max-width: 1199px) {

    /* CHANGE BACKGROUND IMAGE ON SCREEN SIZE ARE < 1100PX (SECTION-1) */
    .section_1_outer {
        width: 100%;
        background-image: url(../../public/static/referrel_two/Section1/responsive_background.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .section_7_outer {
        width: 100%;
        background-image: url(../../public/static/referrel_two/Section7/section_7_background_responsive.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}

@media only screen and (max-width: 899px) {
    .section_9_outer {
        background-image: url('../../public/static/referrel_two/Section9/section_9_background_responsive.png');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
}

@media only screen and (max-width: 599px) {
    .section_5 {
        background: #ffffff00;
    }
}