/* The Modal (background) */
.modal {
    visibility: hidden;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    /* right: 0; */
    left: 0;
    top: 10%;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow-y: auto;
    overflow-x: hidden;
    /* Enable scroll if needed */
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    -webkit-animation-name: fadeIn;
    /* Fade in the background */
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s;
    transition: all 0.6s ease-in-out;

    display: none;
    /* display: flex; */
    align-items: flex-start;
    justify-content: flex-end;
}

/* width */
.modal::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.modal::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.modal::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 100px;
}

/* Handle on hover */
.modal::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.modal.active {
    visibility: visible;
}

/* Modal Content */
.modal_content {
    position: absolute;
    /* right: 0px; */
    left: 0px;
    padding-bottom: 40px;
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.6s;
    animation-name: slideIn;
    animation-duration: 0.6s;

    width: 350px;
    height: 100%;
    border-bottom: 1px solid var(--gray-300, #2e3545);

    background: var(--gray-600, #ffffff);
    /* background: var(--gray-950, #ffffff); */

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    overflow: auto;
    overflow-x: hidden;
}

.modal_content.inactive {
    animation-name: slideOut;
}

/* The Close Button */
.closeBtn {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;

    display: flex;
    height: 40px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;

    border-radius: 5px;
    border: 1px solid var(--gray-300, #ffffff);
    background: var(--gray-400, #333b48);
}

.closeBtn:hover {
    outline: 3px solid #007CB4;
}

.sideBar {
    /* border-radius: 21px; */

    /* background-color: rgb(250, 250, 250);
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.15); */

    width: 100%;
    /* margin-left: 5%; */
    /* width: 30%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* gap: 60px; */

}

.profileContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}

.profileImageNameContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.profileImage {
    width: 106px;
    height: 106px;
    border-radius: 50%;
    margin-top: 20px;
    object-fit: cover;
    object-position: top;
}

.profileNameContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.welcomeText {
    font-size: 16.667px;
    font-family: "Poppins", sans-serif;
    color: rgb(42, 39, 44);
    line-height: 1.2;
    text-align: center;
}

.profileName {
    font-size: 21px;
    font-family: "Poppins", sans-serif;
    color: #007CB4;
    line-height: 1.2;
    font-weight: 500;
    text-align: center;
}

.profileEmailNameContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.profileEmail {
    font-size: 16.667px;
    font-family: "Poppins", sans-serif;
    color: rgb(42, 39, 44);
    line-height: 1.2;
    text-align: center;
}

.profileNumber {
    font-size: 16.667px;
    font-family: "Poppins", sans-serif;
    color: rgb(42, 39, 44);
    line-height: 1.2;
    text-align: center;
}

.profileLinks {
    font-size: 16.667px;
    font-family: "Poppins", sans-serif;
    color: #007CB4;
    line-height: 1.2;
    text-align: center;

}

.profileLink {
    cursor: pointer;
}

.profileLinks>a {
    color: #007CB4;
    text-decoration: none;
}

.profileSocialLinks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 14px;
    margin-top: 4px;
}

.linksContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 8px;
    width: 100%;
    /* z-index: 2; */
    margin-bottom: 200px;
}

.linkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    font-weight: 500;
    width: 100%;
    height: 50px;
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #FAFAFA;
    border-radius: 12px;
}

.linkContainer:hover {

    border-radius: 12px;
    background-color: #EEF1F7;

}

.linkContainerActive {
    border-radius: 12px;
    background: linear-gradient(0deg, #007CB4 0%, #00B0E5 100%);

}

.linkTextActive {
    color: #FFFFFF !important;
}

.linkIconContainer {
    width: 35px;
    height: 100%;
    display: flex;
    align-items: center;
}

.linkIconContainer img {
    max-width: 35px;
}

.linkContainerEngage {
    width: 100%;
    border: 2px solid #007CB4;
    border-radius: 15px;
    box-shadow: -4.5px 5.362px 10px 0px rgba(0, 0, 0, 0.15);
    background-color: #007CB4;
}

.linkContainerEngage:hover {
    border: 2px solid #E8ECF4;
    border-radius: 15px;
    box-shadow: -4.5px 5.362px 10px 0px rgba(0, 0, 0, 0.15);
}


.linkIconContainerEngage {
    padding: 3px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.linkIconContainerEngage img {
    max-width: 140px;
}


.linkText {
    font-size: 21px;
    font-family: "Poppins", sans-serif;
    color: #007CB4;
    line-height: 1.2;
    text-align: left;
}

.userBadgeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;

    border-radius: 30px;

    background: linear-gradient(180deg,
            rgb(174, 134, 37) 0%,
            rgb(247, 239, 138) 30%,
            rgb(210, 172, 71) 58%,
            rgb(237, 201, 103) 100%);

    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
    width: 142px;
    height: 40px;
    margin-bottom: 20px;
}

.userBadgeText {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    color: #303336;
    font-weight: bold;
    line-height: 1.997;
}

/* Add Animation */
@-webkit-keyframes slideIn {
    from {
        left: -300px;
        opacity: 0;
    }

    to {
        left: 0;
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        left: -300px;
        opacity: 0;
    }

    to {
        left: 0;
        opacity: 1;
    }
}

@keyframes slideOut {
    from {
        left: 0px;
        opacity: 1;
    }

    to {
        left: -300px;
        opacity: 0;
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media only screen and (max-width: 1377px) {
    .modal {
        display: flex;
    }
}

@media only screen and (max-width: 530px) {
    .modal_content {
        width: 85vw;
    }

    .closeBtn {
        width: 40px;
        height: 40px;
        top: 8px;
        left: 8px;
        box-shadow: 0px 3.2172px 3.2172px rgba(0, 0, 0, 0.25);
    }

    .closeIcon {
        width: 16px;
        height: 16px;
    }

    .closeBtn:hover {
        outline: 2px solid #007CB4;
    }
}

@media only screen and (max-width: 300px) {
    .modal_content {
        width: 95%;
    }
}


.packageTagPro {
    background: linear-gradient(180deg,
            rgb(174, 134, 37) 0%,
            rgb(247, 239, 138) 30%,
            rgb(210, 172, 71) 58%,
            rgb(237, 201, 103) 100%);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
    width: 170px;
    height: 44px;
    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    /* font-size: 33.383px; */
    font-size: 25px;
    font-family: "Poppins", sans-serif;
    color: #303336;
    font-weight: 600;
}


.packageTagFree {
    background: linear-gradient(180deg, rgb(0, 206, 82) 0%, rgb(4, 232, 4) 100%);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
    width: 170px;
    height: 44px;
    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* font-size: 33.383px; */
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    color: rgb(255, 255, 255);
    font-weight: 600;
}


.probadge {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 25px 10px 10px 0px;
    padding: 5px 10px;
    display: flex;
}

.proBadgeText {
    color: #FFFFFF;
    margin-left: 10px;
    font-weight: 600;
}