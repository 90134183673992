@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&display=swap");

/* font-family: 'Lato', sans-serif; */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --orange-color: #f9a048;
}

.line {
  width: 100%;
  height: 5px;
}

.line img {
  height: 100%;
  width: 100%;
}

.section_1 {
  position: relative;
  /* padding-bottom: 30px; */
}

.section_1_part_1 {
  width: 100%;
  background-image: url("../../public/static/Affiliate_landing/section_2_divison_1_bg.jpg");
}

.section_1_title {
  font-family: "Lato", sans-serif;
  text-align: center;
  color: #fff;
}

.section_1_title span {
  color: var(--orange-color);
}

.section_1_video_wrapper {
  position: absolute;
  left: 0;
  width: 100%;
}

.section_1_video {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
}

.section_1_video video {
  height: 100%;
  width: 100%;
}

.section_1_video_overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 50%;
  padding: 20px 0;
  background-color: #2e5d707e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section_1_part_2 {
  margin-top: 250px;
  min-height: 40vh;
  padding: 0 50px;
}

.section_1_part_2 img {
  height: 100%;
  width: 100%;
}

/* SECTION 2 */


.section_2_division1 {
  width: 100%;
  background-image: url("../../public/static/Affiliate_landing/section_2_divison_1_bg.jpg");
  padding-top: 40px;
}

.section_2_title_img {}

.section_2_video_wrapper {
  background-image: url("../../public/static/Affiliate_landing/round.png");
  padding-top: 50px;
  background-repeat: no-repeat;
  background-position: top right;
  position: relative;
}

.section_2_video_overlay {
  position: absolute;
  top: 50%;
  left: 25%;
  background-color: #fff;
  padding: 20px 10px;
  background-color: #2e5d70cc;
}

.section_2_video_title_hero span {
  color: var(--orange-color);
  writing-mode: vertical-lr;
  text-orientation: sideways-right;
}

.section_2_division2 {
  min-height: 90vh;
  width: 100%;
  background-image: url("../../public/static/Affiliate_landing/devison_2_bg.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}

.section_2_division2_overlay {
  background-image: url('../../public/static/Affiliate_landing/divison_2_overlay.png');
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90%;
}



@media (max-width: 1440px) {
  .section_1_video_wrapper {
    top: 20%;
  }

  .section_1_part_2 {
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .section_1_video_wrapper {
    top: 10%;
  }

  .section_1_video {
    background: none;
    height: auto;
  }

  .section_1_part_2 {
    margin-top: 70px;
  }
}


@media (max-width: 590px) {
  .section_2_title_img {
    display: none;
  }

  .section_2_video_wrapper {
    background: none;
  }

  .section_2_video_overlay {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px 40px;
  }
}