.packageTagPro {
    background: linear-gradient(180deg,
            rgb(174, 134, 37) 0%,
            rgb(247, 239, 138) 30%,
            rgb(210, 172, 71) 58%,
            rgb(237, 201, 103) 100%);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
    min-width: 120px;
    min-height: 30px;
    margin-top: 8px;
    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    /* font-size: 33.383px; */

    font-family: "Lato";
    color: rgb(0, 0, 0);
    font-weight: 600;
}

.packageTagPro img {
    width: 30px;
    margin-left: 5px;
}

.packageTagFree {
    background: linear-gradient(180deg, rgb(0, 206, 82) 0%, rgb(4, 232, 4) 100%);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
    min-width: 100px;
    min-height: 30px;
    border-radius: 20px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    /* font-size: 33.383px; */

    font-family: "Lato";
    color: rgb(255, 255, 255);
    font-weight: 600;
}